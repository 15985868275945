// src/components/AddressReferenceField.tsx
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-admin';
import { FieldProps } from '../types';

const AddressReferenceField: React.FC<FieldProps<any>> = ({
    record,
    source = '',
    label,
    link = 'show',
}) => {
    if (!record || !record.address) {
        return null;
    }

    // Display the address area with an optional link to the address
    if (link) {
        return (
            <Link to={`/addresses/${record.address.id}/show`}>
                {record.address.area}
            </Link>
        );
    }

    return <span>{record.address.area}</span>;
};

AddressReferenceField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

AddressReferenceField.defaultProps = {
    record: {},
    source: 'addressId',
    label: '',
    link: 'show',
};

export default AddressReferenceField;
