import { GET_LIST, GET_MANY } from 'react-admin';
import _ from 'lodash';

const customMapper = dataProvider => async (verb, resource, params) => {
    //only to remove select id from ids array from select all storages
    if (resource === 'storages' && params.ids) {
        params.ids = params.ids.filter(id => id !== 'select');
    } else if (
        resource === 'requests' &&
        params &&
        params.data &&
        params.data.request_lines
    ) {
        params.data.request_lines = params.data.request_lines.filter(
            e => e.storageId !== 'select'
        );
    }

    // Handle pagination for queries with multiple IDs
    if (
        (resource === 'users' ||
            resource === 'orders' ||
            resource === 'addresses') &&
        verb === GET_MANY &&
        params.ids
    ) {
        // Split IDs into chunks of 20
        const chunks = _.chunk(params.ids, 20);
        // Make multiple requests and combine results
        const results = await Promise.all(
            chunks.map(async ids => {
                const chunkParams = { ...params, ids };
                const response = await dataProvider(
                    verb,
                    resource,
                    chunkParams
                );
                return response.data;
            })
        );
        // Combine all results
        return {
            data: results.flat(),
        };
    }

    // Only include order with specific statuses in the order list
    if (resource === 'orders' && verb === GET_LIST) {
        if (
            !params ||
            !params.filter ||
            (!params.filter.status && !params.filter.userId)
        ) {
            params = {
                ...params,
                filter: {
                    ...params.filter,
                    'status[$in][0]': 'confirmed',
                    'status[$in][1]': 'complete',
                    'status[$in][2]': 'cancelled',
                },
            };
        }
    }
    // Added default filter in leads (redeploy)

    // if (resource === 'leads' && verb === GET_LIST) {
    //     if (
    //         !params ||
    //         !params.filter ||
    //         (!params.filter.status && !params.filter.userId)
    //     ) {
    //         params = {
    //             ...params,
    //             filter: {
    //                 ...params.filter,
    //                 'status[$notIn][0]': 'confirmed',
    //             },
    //         };
    //     }
    // }

    // Map leads resource to order resource
    if (resource === 'leads') {
        resource = 'orders';
    }

    if (verb === 'createMany') {
        return Promise.reject(`customMapper: Unknown dataProvider method`);
    }

    return dataProvider(verb, resource, params)
        .then(response => {
            if (Array.isArray(response.data)) {
                response.data = response.data.map((item, idx) => {
                    return {
                        serial: response.skip + idx + 1,
                        ...item,
                    };
                });
            }
            return response;
        })
        .catch(error => {
            if (error.code === 403) {
                // Forbidden
                return; // Makes sure the promise is resolved, so the chain continues
            }
            throw error;
        });
};

export default customMapper;
