import React, { useEffect, useState } from 'react';
import moment from 'moment';
import capitalize from 'capitalize';
import _ from 'lodash';
import { InputAdornment } from '@material-ui/core';
import {
    ArrayInput,
    Create,
    FormDataConsumer,
    SelectInput,
    TabbedForm,
    FormTab,
    TextInput,
    ReferenceInput,
    DateInput,
    BooleanInput,
    AutocompleteInput,
    SelectArrayInput,
    SimpleFormIterator,
    RadioButtonGroupInput,
    NumberInput,
} from 'react-admin';
import {
    CustomerPersona,
    PaymentMethod,
    StorageType,
    OrderType,
    UserType,
    CurrencyByCountryId,
} from '../constants';
import { Field } from 'react-final-form';
import MoneyInput from '../components/MoneyInput';
import BillingCard from '../orders/components/BillingCard';
import ItemsInput from '../orders/components/ItemsInput';
import BoxitPhoneInput from '../components/BoxitPhoneInput';
import { CreateProps, Lead, ValidationErrors } from '../types';
import BoxitSalesCalendarInput from '../components/BoxitSalesCalendarInput';
import PartnerInput from '../components/PartnerInput';
import CountryInput from '../components/CountryInput';
import CityInput from '../components/CityInput';
import CurrencyInput from '../components/CurrencyInput';
import PlaceAutoComplete from '../components/PlaceAutoComplete';
import WhenFieldChanges from '../components/WhenFieldChanges';
import { Source } from '../constants';
import useLocalStorage from '../hooks/useLocalStorage';
import { useGetOne, useDataProvider } from 'react-admin';

const optionText = user => `${user.name} (${user.id})`;

const sanitizeRestProps: (any) => any = ({
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    ...rest
}) => rest;

const FormSpyFieldValues = ({ children, fieldNames }) =>
    fieldNames.reduce(
        (acc, fieldName) => values => (
            <Field name={fieldName} subscription={{ value: true }}>
                {({ input: { value } }) =>
                    acc({ ...values, [fieldName]: value })
                }
            </Field>
        ),
        children
    );

const hasBoxitBoxes = (formData: any): boolean => {
    return (
        formData.items &&
        formData.items.some(
            i =>
                i &&
                i.storagetypeId === StorageType.BB &&
                Number(i.quantity) > 0
        )
    );
};

const validate = values => {
    const errors: ValidationErrors = {};

    if (!values.countryId) {
        errors.countryId = ['errors.required'];
    }

    if (!values.cityId) {
        errors.cityId = ['errors.required'];
    }

    if (values.months > 36) {
        errors.months = ['Must be less than or equal to 36'];
    }

    if (values.existingCustomer) {
        if (!values.userId) {
            errors.userId = ['errors.required'];
        }
    } else {
        if (!values.name) {
            errors.name = ['errors.required'];
        }
        if (!values.email) {
            errors.email = ['errors.required'];
        }
        if (!values.phone) {
            errors.phone = ['errors.required'];
        }

        if (!values.persona) {
            errors.persona = ['errors.required'];
        }
    }

    if (values.type === OrderType.storage) {
        if (!values.need) {
            errors.need = ['errors.required'];
        }
    }

    if (!values.channel) {
        errors.channel = ['errors.required'];
    }

    if (!values.source && !values.existingCustomer) {
        errors.source = ['errors.required'];
    }

    // console.log(errors);
    return errors;
};

const transform = data => {
    const countryId = localStorage.getItem('countryId');
    return {
        ...data,
        ...(data.need && { need: data.need.trim().toLowerCase() }),
        warehouseType:
            countryId && countryId === '2' && !data.warehouseType
                ? ['ac']
                : data.warehouseType,
        source: data.source || 'existingCustomer',
    };
};

const LeadCreate = props => {
    const [currentUserId] = useLocalStorage('userId', null);
    const [countryId] = useLocalStorage('countryId', null);
    const { data: currentUser } = useGetOne('users', currentUserId);
    const dataProvider = useDataProvider();
    const [selectedValue, setSelectedValue] = React.useState('');

    const handleChange = event => {
        setSelectedValue(event.target.value);
    };

    return (
        <Create
            successMessage="resources.leads.notification.create_success"
            {...props}
            transform={transform}
        >
            <TabbedForm
                validate={validate}
                redirect="show"
                {...sanitizeRestProps(props)}
            >
                <FormTab variant="outlined" label="Storage">
                    <BooleanInput
                        style={{ display: 'none' }}
                        source="skipSignupMail"
                        defaultValue={true}
                    />
                    <BooleanInput
                        style={{ display: 'none' }}
                        source="skipOrderMail"
                        defaultValue={true}
                    />

                    <SelectInput
                        source="type"
                        label="Order Type"
                        defaultValue={OrderType.storage}
                        choices={Object.keys(OrderType).map(i => ({
                            id: i,
                            name: capitalize(i.replace('_', ' ')),
                        }))}
                    />

                    {props.permissions.indexOf('staff') !== -1 && (
                        <PartnerInput />
                    )}
                    <CountryInput defaultValue={currentUser?.countryId} />
                    <CityInput
                        defaultValue={currentUser?.countryId === 2 ? 6 : ''}
                    />
                    <CurrencyInput
                        defaultValue={
                            CurrencyByCountryId[currentUser?.countryId]
                        }
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type === OrderType.storage && (
                                <TextInput
                                    variant="outlined"
                                    source="months"
                                    label="Duration"
                                    defaultValue={1}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                Months
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    {/* <MoneyInput source="customerBudget" {...props} /> */}

                    <SelectInput
                        source="paymentMode"
                        label="Payment Mode"
                        choices={Object.keys(PaymentMethod).map(i => ({
                            id: i,
                            name: capitalize(i),
                        }))}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type === OrderType.storage && (
                                <SelectInput
                                    variant="outlined"
                                    label={
                                        currentUser?.countryId === 2
                                            ? 'AC'
                                            : 'Warehouse Type'
                                    }
                                    source="warehouseType"
                                    choices={[
                                        { id: 'ac', name: 'AC' },
                                        { id: 'non-ac', name: 'Non AC' },
                                    ]}
                                    parse={v => v && [v]}
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <TextInput
                        multiline
                        source="terms"
                        label="Additional Terms"
                    />

                    <BooleanInput
                        label="Need Insurance in Quote?"
                        source="insuranceRequired"
                        defaultValue={
                            localStorage.getItem('countryId') === '1'
                                ? true
                                : false
                        }
                    />
                </FormTab>

                <FormTab label="Customer">
                    <BooleanInput
                        source="existingCustomer"
                        label="This is for an existing Customer"
                        defaultValue={false}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.existingCustomer && (
                                <ReferenceInput
                                    label="User"
                                    source="userId"
                                    reference="users"
                                    resource="users"
                                    onChange={value => {
                                        if (value) {
                                            // Fetch user details using dataProvider
                                            dataProvider
                                                .getOne('users', { id: value })
                                                .then(({ data: userData }) => {
                                                    // Update the source based on user status
                                                    const sourceValue = userData.existingCustomer
                                                        ? 'existingCustomer'
                                                        : userData.exCustomer
                                                        ? 'exCustomer'
                                                        : '';
                                                    setSelectedValue(
                                                        sourceValue
                                                    );
                                                })
                                                .catch(error => {
                                                    console.error(
                                                        'Error fetching user details:',
                                                        error
                                                    );
                                                });
                                        }
                                    }}
                                >
                                    <AutocompleteInput
                                        shouldRenderSuggestions={val =>
                                            val.trim().length > 1
                                        }
                                        optionText={optionText}
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.existingCustomer && (
                                <TextInput
                                    variant="outlined"
                                    source="name"
                                    label="Customer Name"
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.existingCustomer && (
                                <TextInput
                                    variant="outlined"
                                    source="email"
                                    type="email"
                                    label="Customer Email"
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.existingCustomer && (
                                <BoxitPhoneInput
                                    source="phone"
                                    label="Customer Phone"
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                    country={formData.countryId}
                                    defaultCountry={currentUser?.countryId}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.existingCustomer && (
                                <SelectInput
                                    source="persona"
                                    label="Customer Persona"
                                    variant="outlined"
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                    choices={Object.keys(CustomerPersona).map(
                                        c => ({
                                            id: c,
                                            name: CustomerPersona[c],
                                        })
                                    )}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.existingCustomer && (
                                <SelectInput
                                    source="customerType"
                                    label="Customer Type"
                                    variant="outlined"
                                    defaultValue={UserType.b2c}
                                    choices={Object.keys(UserType).map(i => ({
                                        id: i,
                                        name: capitalize(i.replace('_', ' ')),
                                    }))}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type === OrderType.storage && (
                                <TextInput
                                    variant="outlined"
                                    multiline
                                    source="need"
                                    label="Need for Storage"
                                />
                            )
                        }
                    </FormDataConsumer>

                    {_.intersection(props.permissions, [
                        'admin',
                        'staff',
                        'global-staff',
                    ]).length > 0 && [
                        <SelectArrayInput
                            key="tags"
                            source="tags"
                            choices={[{ id: 'lead', name: 'lead' }]}
                            defaultValue={['lead']}
                            style={{ display: 'none' }}
                        />,
                        <SelectInput
                            key="channel"
                            source="channel"
                            variant="outlined"
                            choices={[
                                { id: 'google', name: 'Google' },
                                { id: 'youtube', name: 'YouTube' },
                                { id: 'instagram', name: 'Instagram' },
                                { id: 'existing', name: 'Existing Customer' },
                                { id: 'friend', name: 'Referred by friend' },
                                { id: 'facebook', name: 'Facebook' },
                                { id: 'tv', name: 'TV' },
                                { id: 'blog', name: 'Blog' },
                                { id: 'event', name: 'Event' },
                                { id: 'flyer', name: 'Flyer' },
                                { id: 'press', name: 'Press / News' },
                                { id: 'signage', name: 'Signage' },
                                { id: 'truck', name: 'Truck' },
                                { id: 'partner', name: 'Partner' },
                                { id: 'other', name: 'Other' },
                            ]}
                        />,
                        <SelectInput
                            variant="outlined"
                            key="source"
                            source="source"
                            value={selectedValue}
                            onChange={handleChange}
                            choices={Object.keys(Source).map(c => ({
                                id: c,
                                name: Source[c],
                            }))}
                        />,
                        <TextInput
                            key="couponId"
                            label="Coupon"
                            source="couponId"
                            variant="outlined"
                        />,
                    ]}
                    <TextInput multiline variant="outlined" source="notes" />
                </FormTab>

                <FormTab label="Address">
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type === OrderType.moving && (
                                <h3 className="mb-4 text-lg leading-6 font-medium text-gray-800">
                                    Source Address
                                </h3>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.userId && (
                                <ReferenceInput
                                    label="Address"
                                    source="addressId"
                                    reference="addresses"
                                    resource="addresses"
                                    filter={{ userId: formData.userId }}
                                >
                                    <SelectInput
                                        variant="outlined"
                                        source="addressId"
                                        optionText={addr =>
                                            `${addr.line1}, ${addr.area}, ${addr.cityId}`
                                        }
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.addressId && (
                                <TextInput
                                    variant="outlined"
                                    source="line1"
                                    label="Address Line"
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.addressId && (
                                <PlaceAutoComplete source="area" />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type === OrderType.moving && (
                                <h3 className="mb-4 text-lg leading-6 font-medium text-gray-800">
                                    Destination Address
                                </h3>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.userId &&
                            formData.type === OrderType.moving && (
                                <ReferenceInput
                                    label="Address"
                                    source="destAddressId"
                                    reference="addresses"
                                    resource="addresses"
                                    filter={{ userId: formData.userId }}
                                >
                                    <SelectInput
                                        variant="outlined"
                                        source="destAddressId"
                                        optionText={addr =>
                                            `${addr.line1}, ${addr.area}, ${addr.cityId}`
                                        }
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.destAddressId &&
                            formData.type === OrderType.moving && (
                                <TextInput
                                    variant="outlined"
                                    source="destLine1"
                                    label="Address Line"
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.destAddressId &&
                            formData.type === OrderType.moving && (
                                <TextInput
                                    variant="outlined"
                                    source="destArea"
                                    label="Area"
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                />
                            )
                        }
                    </FormDataConsumer>
                </FormTab>

                <FormTab label="Items">
                    <FormDataConsumer>
                        {formDataProps => <ItemsInput {...formDataProps} />}
                    </FormDataConsumer>
                </FormTab>

                <FormTab label="Services">
                    <ArrayInput source="services" label="">
                        <SimpleFormIterator>
                            <ReferenceInput
                                label="service"
                                source="servicetypeId"
                                reference="service-types"
                                resource="service-types"
                                sort={{ field: 'name', order: 'ASC' }}
                            >
                                <SelectInput
                                    source="id"
                                    label="Service"
                                    fullWidth={true}
                                    style={{
                                        display: 'inline',
                                        float: 'left',
                                        paddingRight: '50px',
                                    }}
                                />
                            </ReferenceInput>
                            <MoneyInput source="amount" label="charge" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>

                <FormTab label="Date">
                    <RadioButtonGroupInput
                        label="Type of Operation"
                        source="pickupRequired"
                        defaultValue={true}
                        row={true}
                        choices={[
                            { id: true, name: 'Pickup' },
                            { id: false, name: 'Drop Off' },
                        ]}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <DateInput
                                variant="outlined"
                                label={
                                    formData.pickupRequired
                                        ? 'Pickup Date'
                                        : 'Drop Date'
                                }
                                source="date"
                                parse={v => v && moment(v).format('YYYY-MM-DD')}
                                style={{
                                    display: 'inline',
                                    float: 'left',
                                }}
                            />
                        )}
                    </FormDataConsumer>

                    <SelectInput
                        variant="outlined"
                        label="Time Slot"
                        source="pickupTimeFrom"
                        choices={[
                            {
                                id: 9,
                                name: '9 am - 1 pm',
                            },
                            {
                                id: 13,
                                name: '1 pm - 6 pm',
                            },
                        ]}
                        translateChoice={false}
                        style={{
                            marginLeft: '20px',
                        }}
                    />

                    <NumberInput
                        style={{ display: 'none' }}
                        source="pickupTimeTo"
                    />

                    <WhenFieldChanges
                        field="pickupTimeFrom"
                        becomes={9}
                        set="pickupTimeTo"
                        to={13}
                    />
                    <WhenFieldChanges
                        field="pickupTimeFrom"
                        becomes={13}
                        set="pickupTimeTo"
                        to={18}
                    />

                    {/*
                <SelectInput
                    variant="outlined"
                    label="From"
                    source="pickupTimeFrom"
                    choices={PickupTimeFrom.map(c => ({
                        id: c,
                        name: c,
                    }))}
                    translateChoice={false}
                    fullWidth={true}
                    style={{
                        display: 'inline',
                        float: 'left',
                        marginLeft: '20px',
                        minWidth: '10px',
                        width: '100px',
                    }}
                />

                <SelectInput
                    variant="outlined"
                    label="To"
                    source="pickupTimeTo"
                    choices={PickupTimeTo.map(c => ({
                        id: c,
                        name: c,
                    }))}
                    translateChoice={false}
                    fullWidth={true}
                    style={{
                        display: 'inline',
                        float: 'left',
                        marginLeft: '20px',
                        minWidth: '10px',
                        width: '100px',
                    }}
                />
                */}

                    <div style={{ clear: 'both', width: '100%' }} />

                    <br />
                    <br />
                    {/*
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.countryId && (
                            <BoxitSalesCalendarInput
                                source="date"
                                countryId={formData.countryId}
                            />
                        )
                    }
                </FormDataConsumer>
          */}

                    <Field name="countryId" subscription={{ value: true }}>
                        {({ input: countryIdInput }) => (
                            <Field name="date" subscription={{ value: true }}>
                                {({ input: dateInput }) => (
                                    <BoxitSalesCalendarInput
                                        source="date"
                                        countryId={countryIdInput.value}
                                    />
                                )}
                            </Field>
                        )}
                    </Field>

                    <br />
                    <br />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) && (
                                <BooleanInput
                                    source="newboxRequired"
                                    label="Deliver Boxit Boxes Early"
                                    defaultValue={false}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <DateInput
                                    label="Box Delivery Date"
                                    source="deliveryDate"
                                    parse={v =>
                                        v && moment(v).format('YYYY-MM-DD')
                                    }
                                    style={{
                                        display: 'inline',
                                        float: 'left',
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <SelectInput
                                    variant="outlined"
                                    label="Time Slot"
                                    source="deliveryTimeFrom"
                                    choices={[
                                        {
                                            id: 9,
                                            name: '9 am - 1 pm',
                                        },
                                        {
                                            id: 13,
                                            name: '1 pm - 6 pm',
                                        },
                                    ]}
                                    translateChoice={false}
                                    style={{
                                        marginLeft: '20px',
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <NumberInput
                                    style={{ display: 'none' }}
                                    source="deliveryTimeTo"
                                />
                            )
                        }
                    </FormDataConsumer>

                    <WhenFieldChanges
                        field="deliveryTimeFrom"
                        becomes={9}
                        set="deliveryTimeTo"
                        to={13}
                    />
                    <WhenFieldChanges
                        field="deliveryTimeFrom"
                        becomes={13}
                        set="deliveryTimeTo"
                        to={18}
                    />

                    {/*
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        hasBoxitBoxes(formData) &&
                        !!formData.newboxRequired && (
                            <SelectInput
                                label="From"
                                source="deliveryTimeFrom"
                                choices={PickupTimeFrom.map(c => ({
                                    id: c,
                                    name: c,
                                }))}
                                translateChoice={false}
                                fullWidth={true}
                                style={{
                                    display: 'inline',
                                    float: 'left',
                                    marginLeft: '20px',
                                    minWidth: '10px',
                                    width: '100px',
                                }}
                            />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        hasBoxitBoxes(formData) &&
                        !!formData.newboxRequired && (
                            <SelectInput
                                label="To"
                                source="deliveryTimeTo"
                                choices={PickupTimeTo.map(c => ({
                                    id: c,
                                    name: c,
                                }))}
                                translateChoice={false}
                                fullWidth={true}
                                style={{
                                    display: 'inline',
                                    float: 'left',
                                    marginLeft: '20px',
                                    minWidth: '10px',
                                    width: '100px',
                                }}
                            />
                        )
                    }
                </FormDataConsumer>
                */}

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <hr style={{ clear: 'both', width: '100%' }} />
                            )
                        }
                    </FormDataConsumer>

                    <BooleanInput
                        label="Need Survey?"
                        source="surveyRequired"
                        fullWidth={true}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.surveyRequired && (
                                <DateInput
                                    source="surveyDate"
                                    label="Survey Date"
                                    parse={v =>
                                        v && moment(v).format('YYYY-MM-DD')
                                    }
                                    style={{
                                        display: 'inline',
                                        float: 'left',
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.surveyRequired && (
                                <SelectInput
                                    source="surveyTime"
                                    label="Survey Time"
                                    {...props}
                                    choices={[
                                        { id: 9, name: '9 AM' },
                                        { id: 10, name: '10 AM' },
                                        { id: 11, name: '11 AM' },
                                        { id: 12, name: '12 PM' },
                                        { id: 13, name: '1 PM' },
                                        { id: 14, name: '2 PM' },
                                        { id: 15, name: '3 PM' },
                                        { id: 16, name: '4 PM' },
                                        { id: 17, name: '5 PM' },
                                        { id: 18, name: '6 PM' },
                                    ]}
                                    fullWidth={true}
                                    style={{
                                        display: 'inline',
                                        float: 'left',
                                        marginLeft: '20px',
                                        minWidth: '10px',
                                        width: '100px',
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.surveyRequired && (
                                <hr style={{ clear: 'both', width: '100%' }} />
                            )
                        }
                    </FormDataConsumer>
                </FormTab>

                <FormTab label="Checklist">
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <SelectInput
                                    source="checklist.buildingType"
                                    label="Type of Building"
                                    style={{ width: 300 }}
                                    choices={[
                                        { id: 'Flat', name: 'Flat' },
                                        { id: 'Villa', name: 'Villa' },
                                        { id: 'Hotel', name: 'Hotel' },
                                        { id: 'Office', name: 'Office' },
                                        { id: 'Other', name: 'Other' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is entry permit required"
                                    source="checklist.permitRequired"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is moving insurance required"
                                    source="checklist.movingInsuranceRequired"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is parking slot available"
                                    source="checklist.parkingSlotAvailable"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.parkingSlotAvailable && (
                                <RadioButtonGroupInput
                                    label="Is parking slot inside or outside the building"
                                    source="checklist.isSlotInsideOrOutside"
                                    row={true}
                                    fullWidth={true}
                                    choices={[
                                        {
                                            id: 'Inside',
                                            name: 'Inside',
                                        },
                                        {
                                            id: 'Outside',
                                            name: 'Outside',
                                        },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            formData.checklist.parkingSlotAvailable && (
                                <SelectInput
                                    source="checklist.maximumAllowedLoadingTime"
                                    label="Maximum allowed Loading time"
                                    style={{ width: 300 }}
                                    choices={[
                                        {
                                            id: 'Unlimitted',
                                            name: 'Unlimitted',
                                        },
                                        { id: '1 Hr', name: '1 Hr' },
                                        { id: '2 Hr', name: '2 Hr' },
                                        { id: '3 Hr', name: '3 Hr' },
                                        { id: '4 Hr', name: '4 Hr' },
                                        { id: '5 Hr', name: '5 Hr' },
                                        { id: '6 Hr', name: '6 Hr' },
                                        { id: '7 Hr', name: '7 Hr' },
                                        { id: '8 Hr', name: '8 Hr' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <TextInput
                                    source="checklist.buildingFloor"
                                    label="Building Floor"
                                    style={{ width: 300 }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is lift available"
                                    source="checklist.liftAvailable"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.liftAvailable && (
                                <SelectInput
                                    source="checklist.maximumAllowedLiftTime"
                                    label="Maximum allowed time for Lift"
                                    style={{ width: 300 }}
                                    choices={[
                                        {
                                            id: 'Unlimitted',
                                            name: 'Unlimitted',
                                        },
                                        { id: '1 Hr', name: '1 Hr' },
                                        { id: '2 Hr', name: '2 Hr' },
                                        { id: '3 Hr', name: '3 Hr' },
                                        { id: '4 Hr', name: '4 Hr' },
                                        { id: '5 Hr', name: '5 Hr' },
                                        { id: '6 Hr', name: '6 Hr' },
                                        { id: '7 Hr', name: '7 Hr' },
                                        { id: '8 Hr', name: '8 Hr' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.liftAvailable && (
                                <RadioButtonGroupInput
                                    label="Are there items that would not fit inside the lift"
                                    source="checklist.anyItemsNotFitinsideLift"
                                    fullWidth={true}
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.anyItemsNotFitinsideLift && (
                                <TextInput
                                    multiline
                                    style={{ width: 300 }}
                                    label="If yes details"
                                    source="checklist.liftNotFitDetails"
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="If customer wants to authorize someone"
                                    source="checklist.authorizeOtherPerson"
                                    fullWidth={true}
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.authorizeOtherPerson && (
                                <TextInput
                                    label="Authorized Person Name"
                                    source="checklist.authorizedName"
                                    style={{ width: 300 }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.authorizeOtherPerson && (
                                <BoxitPhoneInput
                                    source="checklist.authorizedPhone"
                                    label="Authorized Person Phone"
                                    style={{ width: 300 }}
                                    country={formData.countryId}
                                    defaultCountry={currentUser?.countryId}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.authorizeOtherPerson && (
                                <TextInput
                                    label="Authorized Person ID Number"
                                    source="checklist.authorizedID"
                                    style={{ width: 300 }}
                                />
                            )
                        }
                    </FormDataConsumer>
                </FormTab>

                <FormTab label="Billing">
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <BillingCard record={formData} />
                        )}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default LeadCreate;
