// src/requests/components/RequestUserField.tsx
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-admin';
import { FieldProps } from '../../types';

const RequestUserField: React.FC<FieldProps<any>> = ({
    record,
    source = '',
    label,
    link = 'show',
}) => {
    if (!record) return null;

    // Get the user data based on the source field
    const userData = source === 'userId' ? record.user : record.assignee;
    if (!userData) return null;

    // Display the user name with an optional link to the user profile
    if (link) {
        return <Link to={`/users/${userData.id}/show`}>{userData.name}</Link>;
    }

    return <span>{userData.name}</span>;
};

RequestUserField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

RequestUserField.defaultProps = {
    record: {},
    source: 'userId',
    label: '',
    link: 'show',
};

export default RequestUserField;
