import React from 'react';
import moment from 'moment';
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    ShowButton,
} from 'react-admin';
import CustomSearchInput from '../components/CustomSearchInput';
import { OrderStatus, OrderType } from '../constants';
import ChipField from '../components/ChipField';
import CityField from '../components/CityField';
import BoxitChipField from '../components/BoxitChipField';
import MoneyField from '../components/MoneyField';
import VolumeField from '../components/VolumeField';
import UserReferenceField from '../components/UserReferenceField';
import OrderPagination from './OrderPagination';
import { SelectArrayInput } from 'react-admin';
import humanize from '../util/humanize';
import { FunctionField } from 'react-admin';
import { Chip } from '@material-ui/core';
import { TextInput } from 'react-admin';

const CustomerRentField = props => {
    const { record } = props;
    return record &&
        record.customerRent &&
        record.rent &&
        record.customerRent !== record.rent ? (
        <MoneyField
            source="customerRent"
            label="Customer Rent"
            showCurrency
            {...props}
        />
    ) : null;
};

const OrderFilter = props => {
    return (
        <Filter variant="outlined" {...props}>
            <CustomSearchInput alwaysOn />

            <SelectInput
                source="type"
                choices={Object.keys(OrderType).map(c => ({
                    id: c,
                    name: humanize(c),
                }))}
            />

            <ReferenceInput
                source="assigneeId"
                reference="users"
                resource="users"
                label="Assignee"
                filter={{ 'roles[$contains]': ['sales-staff'] }}
            >
                <SelectInput />
            </ReferenceInput>

            <ReferenceInput
                source="partnerId"
                reference="partners"
                resource="partners"
                label="Partner"
            >
                <SelectInput />
            </ReferenceInput>

            <ReferenceInput
                source="countryId"
                reference="country"
                resource="country"
                label="Country"
            >
                <SelectInput />
            </ReferenceInput>

            <ReferenceInput
                source="cityId"
                reference="city"
                resource="city"
                label="City"
            >
                <SelectInput />
            </ReferenceInput>

            <SelectArrayInput
                style={{ minWidth: '10rem' }}
                source="status"
                choices={Object.keys(OrderStatus).map(c => ({
                    id: c,
                    name: c,
                }))}
            />

            <ReferenceInput
                source="userId"
                reference="users"
                resource="users"
                label="Customer"
            >
                <AutocompleteInput
                    shouldRenderSuggestions={val => val.trim().length > 2}
                    optionText={user => (user.name ? user.name : user.email)}
                />
            </ReferenceInput>
            <TextInput source="id" label="Order ID" />

            <DateInput
                source="createdAt[$gte]"
                label="Created From"
                parse={v => v && moment(v).startOf('day').format()}
            />
            <DateInput
                source="createdAt[$lte]"
                label="Created To"
                parse={v => v && moment(v).endOf('day').format()}
            />

            <DateInput
                source="date[$gte]"
                label="Date From"
                parse={v => v && moment(v).format('YYYY-MM-DD')}
            />

            <DateInput
                source="date[$lte]"
                label="Date To"
                parse={v => v && moment(v).format('YYYY-MM-DD')}
            />

            <DateInput
                source="renewalDate[$gte]"
                label="Renewal Date From"
                parse={v => v && moment(v).format('YYYY-MM-DD')}
            />

            <DateInput
                source="renewalDate[$lte]"
                label="Renewal Date To"
                parse={v => v && moment(v).format('YYYY-MM-DD')}
            />
        </Filter>
    );
};

const OrderList = ({ permissions = [], ...props }) => {
    return (
        <List
            title="Orders"
            bulkActionButtons={false}
            filters={<OrderFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            pagination={<OrderPagination />}
            perPage={10}
            {...props}
        >
            {/* <Datagrid rowStyle={rowStyle}> */}
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <TextField source="id" />
                <DateField
                    locales="en-IN"
                    source="createdAt"
                    label="Created"
                    showTime
                />
                <ReferenceField
                    label="Partner"
                    source="partnerId"
                    reference="partners"
                    link="show"
                >
                    <TextField source="name" />
                </ReferenceField>
                <UserReferenceField source="assigneeId" label="Assignee" />
                <UserReferenceField label="Customer" />
                {/*<ChipField source="type" />*/}
                <FunctionField
                    label="Type"
                    render={record => {
                        return <Chip label={humanize(record.type)} />;
                    }}
                />
                <CityField />
                <DateField locales="en-IN" source="date" label="Date" />
                <DateField
                    locales="en-IN"
                    source="renewalDate"
                    label="Renewal"
                />
                <TextField source="months" label="Duration" />
                <TextField source="qty" label="Items" sortable={false} />
                <TextField
                    source="currentQty"
                    label="Live Items"
                    sortable={false}
                />
                <VolumeField source="size" label="Size" showUnit />
                <VolumeField
                    source="currentSize"
                    label="Live Size"
                    showUnit
                    sortable={false}
                />
                <CustomerRentField label="Partner Rent" />
                <MoneyField source="rent" label="Boxit Rent" showCurrency />
                <MoneyField
                    source="currentRent"
                    label="Live Rent"
                    showCurrency
                    sortable={false}
                />
                <MoneyField source="rate" label="Rate" showCurrency />
                <BoxitChipField record source="status" list="order" />
                <ChipField source="source" />
                {props.hasShow && <ShowButton style={{ padding: 0 }} />}
            </Datagrid>
        </List>
    );
};

OrderList.defaultProps = {
    resource: 'orders',
    location: { search: '' },
};

export default OrderList;
