// @ts-nocheck
import React from 'react';
import moment from 'moment';
import capitalize from 'capitalize';
import Color from 'color';
import {
    useMediaQuery,
    Theme,
    Card as MuiCard,
    CardContent,
    withStyles,
    Chip,
} from '@material-ui/core';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    downloadCSV,
    DateInput,
    ShowButton,
    Filter,
    List,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    ChipField,
    ReferenceField,
    SimpleList,
    FilterList,
    FilterListItem,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CustomSearchInput from '../components/CustomSearchInput';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
import UserReferenceField from '../components/UserReferenceField';
import BoxitChipField from '../components/BoxitChipField';
import TimeSlotField from '../components/TimeSlotField';
import VolumeField from '../components/VolumeField';
import BoxitPagination from '../components/BoxitPagination';
import { RequestStatus, RequestType } from '../constants';
import CityField from '../components/CityField';
import { getRequestColor } from './utils';
import { SelectArrayInput } from 'react-admin';
import { FunctionField } from 'react-admin';
import humanize from '../util/humanize';
import storageIcon from './icons/storage.png';
import otherIcon from './icons/services.png';
import OrderReferenceField from '../components/OrderReferenceField';
import AddressReferenceField from '../components/AddressReferenceField';
import RequestUserField from './components/RequestUserField';

const useStyles = makeStyles({
    root: {
        padding: '1px 8px',
        color: '#595959',
        background: '#F5F5F5',
        borderRadius: '4px',
    },
});

const rowStyle = record => {
    if (record.status === RequestStatus.complete) {
        return {};
    }

    const reqColor = getRequestColor(record);

    return {
        backgroundColor: Color(reqColor).alpha(0.1),
    };
};

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: 1, // display on the left rather than on the right of the list
            width: '12em',
            marginLeft: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'addressId', 'addresses').then(addresses => {
        fetchRelatedRecords(records, 'teamId', 'teams').then(teams => {
            fetchRelatedRecords(records, 'partnerId', 'partners').then(
                partners => {
                    fetchRelatedRecords(records, 'cityId', 'city').then(
                        cities => {
                            fetchRelatedRecords(
                                records,
                                'warehouseId',
                                'warehouses'
                            ).then(warehouses => {
                                fetchRelatedRecords(
                                    records,
                                    'assigneeId',
                                    'users'
                                ).then(assignees => {
                                    const data = records.map(record => ({
                                        ...record,
                                        AddressLine:
                                            addresses[record.addressId] &&
                                            addresses[record.addressId].line1,
                                        AddressArea:
                                            addresses[record.addressId] &&
                                            addresses[record.addressId].area,
                                        City:
                                            cities[record.cityId] &&
                                            cities[record.cityId].name,
                                        Name: record.user.name,
                                        Partner:
                                            partners[record.partnerId] &&
                                            partners[record.partnerId].name,
                                        Sales:
                                            assignees[record.assigneeId] &&
                                            assignees[record.assigneeId].name,
                                        Phone: record.user.phone,
                                        Type: record.type,
                                        address: record.addressId,
                                        Team:
                                            teams[record.teamId] &&
                                            teams[record.teamId].name,
                                        Warehouse:
                                            warehouses[record.warehouseId] &&
                                            warehouses[record.warehouseId].name,
                                        Time: `${record.from} to ${record.to}`,
                                        Date: record.date,
                                        CBM: record.size,
                                        Rating: record.rating,
                                        Feedback: record.feedback,
                                    }));

                                    const csv = convertToCSV({
                                        data,
                                        fields: [
                                            'City',
                                            'Date',
                                            'Time',
                                            'Partner',
                                            'Sales',
                                            'Name',
                                            'Phone',
                                            'CBM',
                                            'Type',
                                            'AddressLine',
                                            'AddressArea',
                                            'Warehouse',
                                            'Team',
                                            'Vehicle',
                                            'Rating',
                                            'Feedback',
                                        ],
                                    });
                                    downloadCSV(csv, `Request_${Date.now()}`);
                                });
                            });
                        }
                    );
                }
            );
        });
    });
};

const DateFilter = () => (
    <FilterList label="pos.filter.date" icon={<AccessTimeIcon />}>
        <FilterListItem
            label="Today"
            value={{
                date: moment().format('YYYY-MM-DD'),
            }}
        />
        <FilterListItem
            label="Tomorrow"
            value={{
                date: moment().add(1, 'days').format('YYYY-MM-DD'),
            }}
        />
        <FilterListItem
            label="This Week"
            value={{
                'date[$gte]': moment().startOf('week').format('YYYY-MM-DD'),
                'date[$lte]': moment().endOf('week').format('YYYY-MM-DD'),
            }}
        />
        <FilterListItem
            label="Last Week"
            value={{
                'date[$gte]': moment()
                    .subtract(1, 'weeks')
                    .startOf('week')
                    .format('YYYY-MM-DD'),
                'date[$lte]': moment()
                    .subtract(1, 'weeks')
                    .endOf('week')
                    .format('YYYY-MM-DD'),
            }}
        />
        <FilterListItem
            label="This Month"
            value={{
                'date[$gte]': moment().startOf('month').format('YYYY-MM-DD'),
                'date[$lte]': moment().endOf('month').format('YYYY-MM-DD'),
            }}
        />
        <FilterListItem
            label="Last Month"
            value={{
                'date[$gte]': moment()
                    .subtract(1, 'month')
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                'date[$lte]': moment()
                    .subtract(1, 'month')
                    .endOf('month')
                    .format('YYYY-MM-DD'),
            }}
        />
        <FilterListItem
            label="This Year"
            value={{
                'date[$gte]': moment().startOf('year').format('YYYY-MM-DD'),
                'date[$lte]': moment().endOf('year').format('YYYY-MM-DD'),
            }}
        />
    </FilterList>
);

const TypeFilter = () => (
    <FilterList label="pos.filter.type" icon={<LocalOfferIcon />}>
        {Object.values(RequestType).map(i => (
            <FilterListItem
                label={humanize(i)}
                value={{
                    type: i,
                }}
            />
        ))}
    </FilterList>
);

const StatusFilter = () => (
    <FilterList label="pos.filter.status" icon={<MonetizationOnIcon />}>
        {Object.values(RequestStatus).map(i => (
            <FilterListItem
                label={capitalize(i)}
                value={{
                    status: i,
                }}
            />
        ))}
    </FilterList>
);

const FilterSidebar = () => (
    <Card>
        <CardContent>
            <DateFilter />
            <TypeFilter />
            <StatusFilter />
        </CardContent>
    </Card>
);

const RequestFilter = props => (
    <Filter variant="standard" {...props}>
        <CustomSearchInput alwaysOn />

        <ReferenceInput
            source="partnerId"
            reference="partners"
            resource="partners"
            label="Partner"
        >
            <SelectInput />
        </ReferenceInput>

        <SelectArrayInput
            style={{ minWidth: '10rem' }}
            source="status"
            choices={Object.keys(RequestStatus).map(c => ({
                id: c,
                name: c,
            }))}
        />

        <SelectInput
            source="type"
            choices={Object.keys(RequestType).map(c => ({
                id: c,
                name: humanize(c),
            }))}
        />

        <SelectInput
            label="Charges status"
            source="chargeTypes"
            choices={[
                { id: 'filterChargesWithAnd', name: 'filterChargesWithAnd' },
                { id: 'filterChargesWithOr', name: 'filterChargesWithOr' },
                { id: 'filterChargesEmpty', name: 'filterChargesEmpty' },
            ]}
        />

        <ReferenceInput
            source="userId"
            reference="users"
            resource="users"
            label="Customer"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user => (user.name ? user.name : user.email)}
            />
        </ReferenceInput>

        <ReferenceInput
            source="countryId"
            reference="country"
            resource="country"
            label="Country"
        >
            <SelectInput />
        </ReferenceInput>

        <ReferenceInput
            source="cityId"
            reference="city"
            resource="city"
            label="City"
        >
            <SelectInput />
        </ReferenceInput>

        <ReferenceInput
            source="teamId"
            reference="teams"
            resource="teams"
            label="Team"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={choice => `${choice.name}`}
            />
        </ReferenceInput>

        <DateInput
            source="date[$gte]"
            label="Date From"
            parse={v => v && moment(v).format('YYYY-MM-DD')}
        />
        <DateInput
            source="date[$lte]"
            label="Date To"
            parse={v => v && moment(v).format('YYYY-MM-DD')}
        />
    </Filter>
);

const RequestList = props => {
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const classes = useStyles();

    return (
        <List
            title="Requests"
            bulkActionButtons={false}
            exporter={exporter}
            filters={<RequestFilter />}
            aside={<FilterSidebar />}
            sort={{ field: 'date', order: 'DESC' }}
            pagination={<BoxitPagination />}
            perPage={20}
            {...props}
        >
            {isSmall && false ? (
                <SimpleList
                    primaryText={record => record.id}
                    secondaryText={record => `${record.type}`}
                    tertiaryText={record =>
                        new Date(record.date).toLocaleDateString()
                    }
                />
            ) : (
                // <Datagrid rowStyle={rowStyle}>
                <Datagrid>
                    <TextField source="serial" sortable={false} />
                    <TextField source="id" />
                    <ReferenceField
                        label="Partner"
                        source="partnerId"
                        reference="partners"
                        link="show"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <RequestUserField source="assigneeId" label="Assignee" />
                    <RequestUserField source="userId" label="Customer" />
                    <DateField locales="en-IN" source="date" />
                    <FunctionField
                        label="Type"
                        render={record => {
                            return <Chip label={humanize(record.type)} />;
                        }}
                    />
                    <VolumeField source="size" label="CBM" showUnit />
                    <TimeSlotField label="Time" />
                    <CityField />
                    <AddressReferenceField source="addressId" label="Area" />
                    <ReferenceField
                        label="Team"
                        source="teamId"
                        reference="teams"
                        link="show"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        label="Warehouse"
                        source="warehouseId"
                        reference="warehouses"
                        link="show"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <OrderReferenceField source="orderId" label="Order" />
                    <FunctionField
                        label="Charges status"
                        render={record => {
                            const isOrderOrStorage =
                                record.charges.findIndex(
                                    item =>
                                        item.type === 'order' ||
                                        item.type === 'storage'
                                ) !== -1;
                            const isNotOrderOrStorage =
                                record.charges.findIndex(
                                    item =>
                                        item.type !== 'order' &&
                                        item.type !== 'storage'
                                ) !== -1;

                            if (isOrderOrStorage && isNotOrderOrStorage) {
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'Center',
                                            justifyContent: 'center',
                                            gap: '.5rem',
                                        }}
                                    >
                                        <img src={storageIcon} />
                                        <img src={otherIcon} />
                                    </div>
                                );
                            } else if (isOrderOrStorage) {
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'Center',
                                            justifyContent: 'center',
                                            gap: '.5rem',
                                        }}
                                    >
                                        <img src={storageIcon} />
                                    </div>
                                );
                            } else if (isNotOrderOrStorage) {
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'Center',
                                            justifyContent: 'center',
                                            gap: '.5rem',
                                        }}
                                    >
                                        <img src={otherIcon} />
                                    </div>
                                );
                            } else {
                                return <div></div>;
                            }
                        }}
                    />
                    <BoxitChipField record source="status" list="request" />
                    <ShowButton />
                </Datagrid>
            )}
        </List>
    );
};

RequestList.defaultProps = {
    resource: 'requests',
    location: { search: '' },
};

export default RequestList;
