// src/components/OrderReferenceField.tsx
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-admin';
import { FieldProps } from '../types';

const OrderReferenceField: React.FC<FieldProps<any>> = ({
    record,
    source = '',
    label,
    link = 'show',
}) => {
    if (!record || !record.order) {
        return null;
    }

    // Display the order ID with an optional link to the order
    if (link) {
        return (
            <Link to={`/orders/${record.order.id}/show`}>
                {record.order.id}
            </Link>
        );
    }

    return <span>{record.order.id}</span>;
};

OrderReferenceField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

OrderReferenceField.defaultProps = {
    record: {},
    source: 'orderId',
    label: '',
    link: 'show',
};

export default OrderReferenceField;
